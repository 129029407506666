const BASEURL = 
window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
? 'http://127.0.0.1:8080/'
: 'http://45.56.113.160/';




export const priceApi = {
    getPrices: async () => {
        try {
            const response = await fetch(BASEURL + 'prices');
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('cannot fetch prices:', error);
        }
        return {};
    }
}
