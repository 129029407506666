import * as CryptoDetails from './crypto-details';

export const Cryptos = {
    Bitcoin: CryptoDetails.Bitcoin,
    Ethereum: CryptoDetails.Ethereum,
    BNB: CryptoDetails.BNB,
    XRP: CryptoDetails.XRP,
    Solana: CryptoDetails.Solana,
    Dogecoin: CryptoDetails.Dogecoin,
    Tron: CryptoDetails.Tron,
    Cardano: CryptoDetails.Cardano,
    Polkadot: CryptoDetails.Polkadot,
    Litecoin: CryptoDetails.Litecoin,
    Kaspa: CryptoDetails.Kaspa,
    Sui: CryptoDetails.Sui,
    Dogwifhat: CryptoDetails.Dogwifhat,
    Bonk: CryptoDetails.Bonk,
    Brett: CryptoDetails.Brett,
    Avalanche: CryptoDetails.Avalanche,
    ShibaInu: CryptoDetails.ShibaInu,
    Aptos: CryptoDetails.Aptos,
    InternetComputer: CryptoDetails.InternetComputer,
    Monero: CryptoDetails.Monero,
    Pepe: CryptoDetails.Pepe,
    USA: CryptoDetails.USA,
    SNEK: CryptoDetails.SNEK,
    Siacoin: CryptoDetails.Siacoin,
    Chainlink: CryptoDetails.Chainlink,
    BitcoinCash: CryptoDetails.BitcoinCash,
    NEARProtocol: CryptoDetails.NEARProtocol,
    Uniswap: CryptoDetails.Uniswap,
    ArtificialSuperintelligenceAlliance: CryptoDetails.ArtificialSuperintelligenceAlliance,
    Stellar: CryptoDetails.Stellar,
    EthereumClassic: CryptoDetails.EthereumClassic,
    Render: CryptoDetails.Render,
    Bittensor: CryptoDetails.Bittensor,
    Stacks: CryptoDetails.Stacks,
    Filecoin: CryptoDetails.Filecoin,
    Arbitrum: CryptoDetails.Arbitrum,
    Injective: CryptoDetails.Injective,
    ThetaNetwork: CryptoDetails.ThetaNetwork,
    Helium: CryptoDetails.Helium,
    JasmyCoin: CryptoDetails.JasmyCoin,
    Beam: CryptoDetails.Beam,
    Popcat: CryptoDetails.Popcat,
    DOGS: CryptoDetails.DOGS,
    Zcash: CryptoDetails.Zcash,
    Holo: CryptoDetails.Holo,
    Dash: CryptoDetails.Dash,
    BasicAttentionToken: CryptoDetails.BasicAttentionToken,
    SimonsCat: CryptoDetails.SimonsCat,
    BOBO: CryptoDetails.BOBO,
};
