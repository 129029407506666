const BASEURL = 
window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
? 'http://127.0.0.1:8080/'
: 'http://45.56.113.160/';


export const voteApi = {
    postVote: async (name, voteBool) => {
        const voteUrl = BASEURL + 'vote';
        try {
            const response = await fetch(voteUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: name, vote: voteBool })
            });
            if (response.status === 429) {
                return {data : null, status : response.status}
            }
            if (!response.ok) {
                return {data : null, status : response.status}
            }
            const data = await response.json();
            return {data : data, status : response.status}
        } catch (error) {
            console.error('Error:', error, JSON.stringify({ name, voteBool }));
            return {data : null, status : 500}
        }
    }
}

