export const Bitcoin = {
    text: 'Bitcoin',
    backgroundImage: '/crypto/Bitcoin.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/bitcoin/',
    altBackgroundColor: null
}

export const Ethereum = {
    text: 'Ethereum',
    backgroundImage: '/crypto/Ethereum.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/ethereum/',
    altBackgroundColor: '#fff'
}

export const BNB = {
    text: 'BNB',
    backgroundImage: '/crypto/BNB.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/binance-coin/',
    altBackgroundColor: null
}

export const Solana = {
    text: 'Solana',
    backgroundImage: '/crypto/Solana.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/solana/',
    altBackgroundColor: '#192a56'
}

export const XRP = {
    text: 'XRP',
    backgroundImage: '/crypto/XRP.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/ripple/',
    altBackgroundColor: null
}

export const Dogecoin = {
    text: 'Dogecoin',
    backgroundImage: '/crypto/dogecoin.svg',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/dogecoin/',
    altBackgroundColor: null
}

export const Tron = {
    text: 'Tron',
    backgroundImage: '/crypto/TRON.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/tron/',
    altBackgroundColor: '#24272b'
}

export const Cardano = {
    text: 'Cardano',
    backgroundImage: '/crypto/Cardano.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/cardano/',
    altBackgroundColor: '#f0f0f0'
}

export const Polkadot = {
    text: 'Polkadot',
    backgroundImage: '/crypto/Polkadot.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/polkadot-new/',
    altBackgroundColor: '#e6e6e6'
}

export const Litecoin = {
    text: 'Litecoin',
    backgroundImage: '/crypto/litecoin.svg',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/litecoin/',
    altBackgroundColor: null
}

export const Kaspa = {
    text: 'Kaspa',
    backgroundImage: '/crypto/Kaspa.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/kaspa/',
    altBackgroundColor: '#fff'
}

export const Sui = {
    text: 'Sui',
    backgroundImage: '/crypto/Sui.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/sui/',
    altBackgroundColor: null
}

export const Dogwifhat = {
    text: 'Dogwifhat',
    backgroundImage: '/crypto/dogwifhat.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/dogwifhat/',
    altBackgroundColor: null
}

export const Bonk = {
    text: 'Bonk',
    backgroundImage: '/crypto/Bonk.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/bonk1/',
    altBackgroundColor: null
}

export const Brett = {
    text: 'Brett',
    backgroundImage: '/crypto/Brett.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/brettcoin/',
    altBackgroundColor: null
}

export const Avalanche = {
    text: 'Avalanche',
    backgroundImage: '/crypto/Avalanche.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/avalanche/',
    altBackgroundColor: null
}

export const ShibaInu = {
    text: 'Shiba Inu',
    backgroundImage: '/crypto/shiba-inu.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/shiba-inu/',
    altBackgroundColor: null
}

export const InternetComputer = {
    text: 'Internet Computer',
    backgroundImage: '/crypto/InternetComputer.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/internet-computer/',
    altBackgroundColor: '#24272b'
}

export const Monero = {
    text: 'Monero',
    backgroundImage: '/crypto/Monero.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/monero/',
    altBackgroundColor: null
}

export const Pepe = {
    text: 'Pepe',
    backgroundImage: '/crypto/Pepe.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/pepe-cash/',
    altBackgroundColor: null
}

export const Aptos = {
    text: 'Aptos',
    backgroundImage: '/crypto/Aptos.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/aptos/',
    altBackgroundColor: null
}

export const USA = {
    text: 'USA',
    backgroundImage: '/crypto/usa.webp',
    coinMarketCapUrl: 'https://www.taptools.io/charts/token/d8eb52caf3289a2880288b23141ce3d2a7025dcf76f26fd5659add06.bed02d7e9a2975152001c0942b66534d3fb8290beb7915b26a1021dc2dd1da97',
    altBackgroundColor: '#fff'
}
export const SNEK = {
    text: 'SNEK',
    backgroundImage: '/crypto/snek.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/snek/',
    altBackgroundColor: null
}

export const Siacoin = {
    text: 'Siacoin',
    backgroundImage: '/crypto/siacoin.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/siacoin/',
    altBackgroundColor: '#fff'
}

export const Chainlink = {
    text: 'Chainlink',
    backgroundImage: '/crypto/Chainlink.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/chainlink/',
    altBackgroundColor: '#1A202C'
}

export const BitcoinCash = {
    text: 'Bitcoin Cash',
    backgroundImage: '/crypto/bitcoin-cash.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/bitcoin-cash/',
    altBackgroundColor: null
}

export const NEARProtocol = {
    text: 'NEAR Protocol',
    backgroundImage: '/crypto/near-protocol.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/near-protocol/',
    altBackgroundColor: '#2E3440'
}

export const Uniswap = {
    text: 'Uniswap',
    backgroundImage: '/crypto/Uniswap.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/uniswap/',
    altBackgroundColor: '#fff'
}

export const ArtificialSuperintelligenceAlliance = {
    text: 'Artificial Superintelligence Alliance',
    backgroundImage: '/crypto/asi.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/artificial-superintelligence-alliance/',
    altBackgroundColor: null
}

export const Stellar = {
    text: 'Stellar',
    backgroundImage: '/crypto/Stellar.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/stellar/',
    altBackgroundColor: '#fff'
}

export const EthereumClassic = {
    text: 'Ethereum Classic',
    backgroundImage: '/crypto/ethereum-classic.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/ethereum-classic/',
    altBackgroundColor: '#fff'
}

export const Render = {
    text: 'Render',
    backgroundImage: '/crypto/Render.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/render/',
    altBackgroundColor: null
}

export const Bittensor = {
    text: 'Bittensor',
    backgroundImage: '/crypto/Bittensor.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/bittensor/',
    altBackgroundColor: null
}

export const Stacks = {
    text: 'Stacks',
    backgroundImage: '/crypto/Stacks.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/stacks/',
    altBackgroundColor: null
}

export const Filecoin = {
    text: 'Filecoin',
    backgroundImage: '/crypto/Filecoin.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/filecoin/',
    altBackgroundColor: null
}

export const Arbitrum = {
    text: 'Arbitrum',
    backgroundImage: '/crypto/Arbitrum.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/arbitrum/',
    altBackgroundColor: null
}

export const Injective = {
    text: 'Injective',
    backgroundImage: '/crypto/Injective.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/injective/',
    altBackgroundColor: '#1A202C'
}

export const ThetaNetwork = {
    text: 'Theta Network',
    backgroundImage: '/crypto/theta-network.svg',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/theta-network/',
    altBackgroundColor: null
}

export const Helium = {
    text: 'Helium',
    backgroundImage: '/crypto/Helium.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/helium/',
    altBackgroundColor: null
}

export const JasmyCoin = {
    text: 'JasmyCoin',
    backgroundImage: '/crypto/JasmyCoin.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/jasmy/',
    altBackgroundColor: null
}

export const Beam = {
    text: 'Beam',
    backgroundImage: '/crypto/Beam.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/onbeam/',
    altBackgroundColor: null
}

export const Popcat = {
    text: 'Popcat (SOL)',
    backgroundImage: '/crypto/popcat-sol.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/popcat-sol/',
    altBackgroundColor: null
}

export const DOGS = {
    text: 'DOGS',
    backgroundImage: '/crypto/dogs.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/dogs/',
    altBackgroundColor: null
}

export const Zcash = {
    text: 'Zcash',
    backgroundImage: '/crypto/zcash.svg',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/zcash/',
    altBackgroundColor: null
}

export const Holo = {
    text: 'Holo',
    backgroundImage: '/crypto/holo.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/holo/',
    altBackgroundColor: null
}

export const Dash = {
    text: 'Dash',
    backgroundImage: '/crypto/dash.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/dash/',
    altBackgroundColor: '#333'
}

export const BasicAttentionToken = {
    text: 'Basic Attention Token',
    backgroundImage: '/crypto/basic-attention-token.svg',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/basic-attention-token/',
    altBackgroundColor: '#24272b'
}

export const SimonsCat = {
    text: 'Simon\'s Cat',
    backgroundImage: '/crypto/simons-cat.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/simonscat/',
    altBackgroundColor: null
}

export const BOBO = {
    text: 'BOBO',
    backgroundImage: '/crypto/bobo.png',
    coinMarketCapUrl: 'https://coinmarketcap.com/currencies/bobo-coin/',
    altBackgroundColor: null
}

