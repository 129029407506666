import React, { useEffect, useRef, useState, memo } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { randomPosition } from '../utils/coords';


const Token = ({ token, size, initState,
  scale, openModal, currentToken }) => {
  const [currentScale, setCurrentScale] = useState(0);
  const [isScaling, setIsScaling] = useState(false);
  const glow = token.bearVotes > token.bullVotes ? 'glow-red' : 'glow-green';
  const moveRadius = 30;

  
  const [scaleSpring, springApi] = useSpring(() => ({
    from: { scale: currentScale, opacity: 0 },
    to: { scale: 1.0, opacity: 1 },
    config: { duration: initState.spawnDelay },
  }))

  const handleClick = () => {
    currentToken(token);
    openModal({ open: true })
  }

  useEffect(() => {
    // const newScale = calculateTokenScale();
    const newScale = scale;
    const fromScale = isNaN(currentScale) ? 0 : currentScale;
    // console.log("scaling",token, fromScale, newScale)
    if (!isScaling && fromScale !== newScale) {
      springApi.start({
        from: { scale: fromScale },
        to: { scale: newScale },
        config: { duration: 800 },
        onStart: () => setIsScaling(true),
        onRest: () => setIsScaling(false)
      });
      setCurrentScale(newScale);
    }
  }, [scale, isScaling, currentScale, springApi]);

  const floatSpring = useSpring({
    from: { x: 0, y: 0 },
    to: [
      ...Array.from({ length: 20 }, () => ({ ...randomPosition(moveRadius), delay: Math.random() * 3000 })),
      { x: 0, y: 0, delay: Math.random() * 2000 },
    ],
    loop: true,
    config: { mass: 20, tension: 90, friction: 50 },
  })

  const glowColor = glow === 'glow-red' ? 'rgba(255, 0, 0, 0.7)' : 'rgba(0, 255, 0, 0.7)';

  return (
    <animated.div
      style={{
        position: 'absolute',
        left: initState.x,
        top: initState.y,
        width: size,
        height: size,
        borderRadius: '50%',
        background: `url(${token.backgroundImage}) center/contain no-repeat`,
        backgroundColor: token.altBackgroundColor || 'transparent',
        boxShadow: `inset -5px -5px 15px rgba(0, 0, 0, 0.3), 
                    inset 5px 5px 15px rgba(255, 255, 255, 0.3), 
                    0px 0px 10px 2px ${glowColor}`,
        ...floatSpring,
        ...scaleSpring,
      }}
      onClick={handleClick}
    />
  );
};

export default memo(Token);

